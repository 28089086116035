




































































































import { Observer } from 'mobx-vue'
import { Component, PropSync, Vue } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import { autorun, IReactionDisposer } from 'mobx'

@Observer
@Component
export default class AccountDetailDialog extends Vue {
  @PropSync('drawer') drawerDialog!: boolean
  wallet = walletStore

  procced = ''
  btn = ''
  text = ''

  disposes: IReactionDisposer[] = []

  async submitKyc() {
    console.log("======submitKYC")
    if (!this.wallet.synapsClient) {
      await walletStore.createSynapsSession()
    }
    console.log("=========1111")
    document.getElementById('synaps-btn')?.click()
  }

  async promise() {
    if (!this.wallet.isRegisted) {
      if (this.$route.path !== '/register') this.$router.push('/register')
      this.wallet.showDetailWalletDialog(false)
      this.drawerDialog = false
    } else if (!this.wallet.isLogin) {
      const res = await this.wallet.signIn()
      this.wallet.showDetailWalletDialog(false)
    } else {
      this.wallet.logout()
    }
  }

  mounted() {
    this.disposes = [
      autorun(() => {
        if (!this.wallet.isRegisted) {
          this.procced = 'btnC'
          this.btn = 'btnD'
          this.text = 'Register'
        } else if (!this.wallet.isLogin) {
          this.procced = 'error black--text'
          this.btn = 'btnD'
          this.text = 'Login'
        } else {
          this.btn = 'btnC'
          this.text = 'Logout'
          if (this.wallet.isKycVerified) {
            this.procced = 'btnA--disabled'
          } else {
            this.procced = 'error black--text'
          }
        }
      }),
    ]
  }

  get height() {
    return this.$vuetify.breakpoint.smOnly ? 50 : 60
  }

  get form() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
      case 'sm':
        return 320
      case 'md':
        return 635
      case 'lg':
      case 'xl':
        return 719
    }
  }

  async copyAddress() {
    if (this.wallet.account) {
      await navigator.clipboard.writeText(this.wallet.account)
    }
  }

  beforeDestoy() {
    this.disposes.forEach((d) => d())
  }
}
